import React, { useEffect, useState } from "react";
import { performCheck } from "../data/api-connector";

const Today = (): JSX.Element => {
  const [result, setResult] = useState<boolean>();

  useEffect(() => {
    performCheck(new Date()).then((res) => {
      setResult(res);
    });
  }, []);

  return (
    <div
      className={`App ${
        result === undefined ? "waiting" : result ? "yes" : "no"
      }`}
    >
      <div>
        <h3>Are there chicken tenders for lunch today?</h3>
        <h1>{result === undefined ? "Loading..." : result ? "Yes" : "No"}</h1>
        <a href="/tomorrow">Check tomorrow</a>
      </div>
    </div>
  );
};

export default Today;
