import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import Today from "./routes/Today";
import Tomorrow from "./routes/Tomorrow";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Today />,
  },
  {
    path: "/tomorrow",
    element: <Tomorrow />,
  },
]);

const root: ReactDOM.Root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
