const API_URL = "https://legacy.cafebonappetit.com/api/2/menus?cafe=1374&date=";

export const performCheck = async (date: Date): Promise<boolean> => {
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const url = API_URL + year + "-" + month + "-" + day;
  return await fetch(url)
    .then((res) => res.json())
    .then((data) => {
      let found = false;
      data = data.items;
      for (let item in data) {
        if (data[item].label === "chicken tenders") {
          found = true;
          break;
        }
      }
      return found;
    });
};
